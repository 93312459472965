import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./home-controllers"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

require("./packs/header");
require("./packs/sliders");
require("./packs/scrolling");
require("./packs/animation");
require("./packs/banner");
require("./packs/video");
require("./packs/more-events");



